/* --- Theme Colors --- */


/* --- Basics --- */

body {font-family: 'Google Sans', sans-serif; background-color: #E1E8ED;}
/* --- F3F2EF --- #BCC4FC;*/ 

/* h1 {font-size: 2.4rem; font-weight: 600;}
h2 {font-size: 1.9rem; font-weight: 600;}
h3 {font-size: 1.5rem; font-weight: 600;}
h4 {font-size: 1.25rem; font-weight: 600;}
h5 {font-size: 1rem; font-weight: 600;}
h6 {font-size: 0.9rem; font-weight: 600;} */

.hilite:hover {
  background-color: #E1E8ED; 
  color: #031080
}
  
.hidark:hover {
  background-color:#5C6CFA;
  color: #FFF;
}

.hirich:hover {
  background-color:#CFB53B;
  color: #FFF;
}

.hitext:hover {
  /* background-color: #E1E8ED;  */
  color: #031080
}

.box:focus {
  /* border: 0.02rem solid #5C6CFA; */
  background-color: #EDE7F6;
}

.cursor { cursor: pointer}

.text-color-dark {color: #1A237E}
.text-color-main {color: #283593}
.text-color-next {color: #304FFE}
.text-color-lite {color: #BCC4FC}
.text-color-tone {color: #5C7AA5}
.text-color-wite {color: #FFF}
.text-color-gold{color: #CFB53B;}
.text-color-tone{color: #657786;}
.text-color-tint{color: #AAB8C2;}
.text-color-lite{color: #E1E8ED;}
.text-color-warning {color: #faad14;}
.text-color-success {color: #52c41a;}
.text-color-danger {color: #f5222d;}

.back-color-base {background-color: #0D47A1}
.back-color-basx {background-color: #1565C0}
.back-color-basz {background-color: #1976D2}
.back-color-dark {background-color: #0D47A1}
.back-color-main {background-color: #1565C0}
.back-color-next {background-color: #2962FF}
.back-color-lite {background-color: #E3F2FD}
.back-color-tone {background-color: #5C7AA5}
.back-color-wite {background-color: #FFF}
.back-color-gold {background-color: #CFB53B;}
.back-color-tone {background-color: #AAB8C2;}
.back-color-tint {background-color: #ECF1F4;}
.back-color-lite {background-color: #F5F8FA;}
.back-color-wite {background-color: #FFF;}
.back-color-warning {background-color: #faad14;}
.back-color-success {background-color: #52c41a;}
.back-color-danger {background-color: #f5222d;}

.back-color-none {background-color: transparent;}

.caption-xl {font-size: 3.7rem;}
.caption-wd {font-size: 3.3rem;}
.caption-md {font-size: 2.4rem;}
.caption-sm {font-size: 1.5rem;}

.rounded-sm {border-radius: 0.24rem}
.rounded-md {border-radius: 0.42rem}
.rounded-wd {border-radius: 0.6rem}
.rounded-xx {border-radius: 600px}
.rounded-none {border-radius: 0rem}
.rounded-top-sm {border-top-left-radius: 0.24rem; border-top-right-radius: 0.24rem;}
.rounded-top-md {border-top-left-radius: 0.42rem; border-top-right-radius: 0.42rem;}
.rounded-top-wd {border-top-left-radius: 0.60em; border-top-right-radius: 0.60rem;}
.rounded-top-none {border-top-left-radius: 0em; border-top-right-radius: 0rem;}
.rounded-bottom-sm {border-bottom-left-radius: 0.24rem; border-bottom-right-radius: 0.24rem;}
.rounded-bottom-md {border-bottom-left-radius: 0.42rem; border-bottom-right-radius: 0.42rem;}
.rounded-bottom-wd {border-bottom-left-radius: 0.60em; border-bottom-right-radius: 0.60rem;}
.rounded-bottom-none {border-bottom-left-radius: 0em; border-bottom-right-radius: 0rem;}

.border-none {border: 0em}
.border {border-style: solid; border-width: 0.25px; border-color: #E1E8ED ; }
.border-top {border-top-style: solid; border-top-width: 0.25px; border-color: #E1E8ED ; }
.border-bottom {border-bottom-style: solid; border-bottom-width: 0.25px; border-color: #E1E8ED ; }

.height-sm {height: 2.4rem;}
.height-md {height: 2.7rem;}
.height-wd {height: 3.3rem;}
.height-xl {height: 3.7rem;}

.text-lead {font-size:large;}
.text-small {font-size:small;}
.text-mini {font-size:smaller;}
.text-bold {font-weight: bold;}
.text-start {text-align:left;}
.text-end {text-align:right;}
.text-center {text-align:center;}

.text-icon-sm {font-size:1.5rem; line-height: 2rem;}
.text-icon-md {font-size:2.0rem; line-height: 2rem;}
.text-icon-wd {font-size:3.3rem;}

.text-sm {
	overflow : hidden;
	text-overflow:'Read More';
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.text-md {
	overflow : hidden;
	text-overflow:'Read More';
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.text-wd {
	overflow : hidden;
	text-overflow:'Read More';
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.media-auto {width:100%; height: auto;}

.m-0 {margin: 0;}
.m-1 {margin: 0.5rem;}
.m-2 {margin: 1.0rem;}
.m-3 {margin: 1.5rem;}
.mx-0 {margin-left: 0; margin-right: 0;}
.mx-1 {margin-left: 0.5rem; margin-right: 0.5rem;}
.mx-2 {margin-left: 1.0rem; margin-right: 1.0rem;}
.mx-3 {margin-left: 1.5rem; margin-right: 1.5rem;}
.mx-x {margin-left: 1.5rem; margin-right: 1.5rem;}
.ms-0 {margin-left: 0;}
.ms-1 {margin-left: 0.5rem;}
.ms-2 {margin-left: 1.0rem;}
.ms-3 {margin-left: 1.5rem;}
.me-0 {margin-right: 0;}
.me-1 {margin-right: 0.5rem;}
.me-2 {margin-right: 1.0rem;}
.me-3 {margin-right: 1.5rem;}
.my-0 {margin-top: 0; margin-bottom: 0;}
.mt-0 {margin-top: 0; }
.mt-1 {margin-top: 0.5rem;}
.mt-2 {margin-top: 1.0rem;}
.mt-3 {margin-top: 1.5rem;}
.mb-0 {margin-bottom: 0;}
.mb-1 {margin-bottom: 0.5rem;}
.mb-2 {margin-bottom: 1.0rem;}
.mb-3 {margin-bottom: 1.5rem;}
.mb-x {margin-bottom: 1.5rem;}

.p-0 {padding: 0;}
.p-1 {padding: 0.5rem;}
.p-2 {padding: 1.0rem;}
.p-3 {padding: 1.5rem;}
.px-0 {padding-left: 0; padding-right: 0;}
.px-1 {padding-left: 0.5rem; padding-right: 0.5rem;}
.px-2 {padding-left: 1.0rem; padding-right: 1.0rem;}
.px-3 {padding-left: 1.5rem; padding-right: 1.5rem;}
.ps-0 {padding-left: 0;}
.pe-0 {padding-right: 0;}
.py-0 {padding-top: 0; padding-bottom: 0;}
.py-1 {padding-top: 0.5rem; padding-bottom: 0.5rem;}
.py-2 {padding-top: 1.0rem; padding-bottom: 1.0rem;}
.py-3 {padding-top: 1.5rem; padding-bottom: 1.5rem;}
.pt-0 {padding-top: 0; }
.pt-1 {padding-top: 0.5rem;}
.pt-2 {padding-top: 1.0rem;}
.pt-3 {padding-top: 1.5rem;}
.pb-0 {padding-bottom: 0;}
.pb-1 {padding-bottom: 0.5rem;}
.pb-2 {padding-bottom: 1.0rem;}
.pb-3 {padding-bottom: 1.5rem;}

.d-none {display: none}

.w-100 {width: 100%;}

.d-flex-start {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
  }

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  width: 5px;
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  width: 1em;
  background-color: lightgrey;
  outline: 0px solid lightgrey;
  border-radius: 2rem;
}

@media only screen and (max-width: 992px) {
  .mx-x {margin-left: 1rem; margin-right: 1rem;}
  .mb-x {margin-bottom: 1rem;}
}

@media only screen and (max-width: 768px) {
  .mx-x {margin-left: 0rem; margin-right: 0rem;}
  .mb-x {margin-bottom: 0.5rem;}
}


@media print {
  .newpage {page-break-before: always;}
}