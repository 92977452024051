.name_one{
    font-family: "Sofia", sans-serif;
    font-size: 14px;
    font-weight: bold;
    
}

.name_two{
    font-family: "Trirong", serif;
    font-size: 14px;
    font-weight: bold;
}

.name_three{
    font-family: "Audiowide", sans-serif;
    font-size: 14px;
    font-weight: bold;
}

.name_four{
    font-family: "Roboto";  
    font-size: 14px;
    font-weight: bold;
}

.name_five{
    font-family: "Lobster";  
    font-size: 14px;
    font-weight: bold;
}

.name_six{
    font-family: "Chelsea Market";  
    font-size: 14px;
    font-weight: bold;
}

.name_seven{
    font-family: "Great Vibes";  
    font-size: 14px;
    font-weight: bold;
}

.name_eight{
    font-family: 'Fuzzy Bubbles', 'cursive';
    font-size: 14px;
    font-weight: bold;
}

.name_nine{
    font-family: "Inspiration", cursive;
    font-size: 14px;
    font-weight: bold;
}

